import React from 'react';
import { motion } from 'framer-motion';
import { css } from '@emotion/core';

const style = {
  width: 12,
  height: 12,
  opacity: 1,
  margin: 8,
  borderRadius: '0.2rem',
  display: 'inline-block',
  background: '#ffffff',
};

const variants = {
  start: {
    scale: 0.2,
    rotate: 0,
  },
  end: {
    scale: 1,
    rotate: 360,
  },
};

export default function Loader(props) {
  return (
    <div
      css={css`
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        justify-content: center;
      `}
    >
      <motion.div
        style={style}
        variants={variants}
        initial={'start'}
        animate={'end'}
        transition={{
          repeat: 'Infinity',
          repeatType: 'reverse',
          ease: 'anticipate',
          duration: 1,
          delay: 0,
        }}
      />
      <motion.div
        style={style}
        variants={variants}
        initial={'start'}
        animate={'end'}
        transition={{
          repeat: 'Infinity',
          repeatType: 'reverse',
          ease: 'anticipate',
          duration: 1,
          delay: 0.2,
        }}
      />
      <motion.div
        style={style}
        variants={variants}
        initial={'start'}
        animate={'end'}
        transition={{
          repeat: 'Infinity',
          repeatType: 'reverse',
          ease: 'anticipate',
          duration: 1,
          delay: 0.4,
        }}
      />
      <motion.div
        style={style}
        variants={variants}
        initial={'start'}
        animate={'end'}
        transition={{
          repeat: 'Infinity',
          repeatType: 'reverse',
          ease: 'anticipate',
          duration: 1,
          delay: 0.6,
        }}
      />
      <motion.div
        style={style}
        variants={variants}
        initial={'start'}
        animate={'end'}
        transition={{
          repeat: 'Infinity',
          repeatType: 'reverse',
          ease: 'anticipate',
          duration: 1,
          delay: 0.8,
        }}
      />
    </div>
  );
}
