import React, { useState, useCallback, useEffect } from 'react';
import { navigate } from '@reach/router';
import './styles.css';
import { css } from '@emotion/core';
import netlifyIdentity from 'netlify-identity-widget';
import Chart from 'chart.js';
import { chartColors } from '../utils';
import Lottie from 'react-lottie';
import scrollTo from 'gatsby-plugin-smoothscroll';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as animationData from '../../images/loader-bot.json';
import { Lion as Button } from 'react-button-loaders';
import ReactTooltip from 'react-tooltip';
import Loader from '../components/loader';
import Helmet from 'react-helmet';
import {
  UilVideo,
  UilAngleDown,
  UilLock,
  UilQuestionCircle,
  UilArrowUp,
  UilArrowDown,
} from '@iconscout/react-unicons';
import { UisUnlock, UisCheckCircle } from '@iconscout/react-unicons-solid';
const axios = require('axios');
export default props => {
  const [buttonState, setButtonState] = useState('');
  const [subscriberCount, setSubscriberCount] = useState(0);
  const [avgViewCount, setAvgViewCount] = useState(0);
  const [avgEngagementPerVideo, setAvgEngagementPerVideo] = useState(0);
  const [
    avgChangeInEngagementPerVideo,
    setAvgChangeInEngagementPerVideo,
  ] = useState(0);
  const [avgReactionsPerVideo, setAvgReactionsPerVideo] = useState(0);
  const [
    avgChangeInReactionsPerVideo,
    setAvgChangeInReactionsPerVideo,
  ] = useState(0);
  const [avgCommentsPerVideo, setAvgCommentsPerVideo] = useState(0);
  const [
    avgChangeInCommentsPerVideo,
    setAvgChangeInCommentsPerVideo,
  ] = useState(0);
  const [ytChannelUrl, setYtChannelUrl] = useState('');
  const [isStatsVisible, setIsStatsVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [performanceChart, setPerformanceChart] = useState();
  const [fanAnalysisChart, setFanAnalysisChart] = useState();
  const [earningsAnalysisChart, setEarningsAnalysisChart] = useState();
  const [selectedTimeline, setSelectedTimeline] = useState('10 videos');
  const [hoveredLink, setHoveredLink] = useState('');
  const [isTimelineOptionsVisible, setIsTimelineOptionsVisible] = useState(
    false,
  );
  const [channelTitle, setChannelTitle] = useState('');
  const [channelAvatar, setChannelAvatar] = useState('');
  const [trueFanCount, setTrueFanCount] = useState(0);
  const [superFanCount, setSuperFanCount] = useState(0);
  // const [totalAdRevenueMax, setTotalAdRevenueMax] = useState(0);
  // const [totalAdRevenueMin, setTotalAdRevenueMin] = useState(0);
  // const [avgAdRevenue, setAvgAdRevenue] = useState(0);
  //const [totalDirectRevenue, setTotalDirectRevenue] = useState(0);
  const [showChannelInfoCard, setShowChannelInfoCard] = useState(false);
  const [width, setWidth] = useState();
  const [height, setHeight] = useState();
  const [isContactFormSubmitted, setIsContactFormSubmitted] = useState(false);
  const [contactFormData, setContactFormData] = useState({});
  const [
    contactFormSubmissionProcessing,
    setContactFormSubmissionProcessing,
  ] = useState(false);
  const updateDimensions = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };
  const [earningsMultiplier, setEarningsMultiplier] = useState(1.5);
  useEffect(() => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
    netlifyIdentity.init();
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  });

  const handleIdentity = e => {
    e.preventDefault();
    netlifyIdentity.open();
  };

  const useQuery = () => {
    return new URLSearchParams(props.location.search);
  };
  useEffect(() => {
    handleSetChannelUrl();
  }, []);
  const handleSetChannelUrl = useCallback(() => {
    let query = useQuery();
    if (query.get('channelUrl')) {
      console.log(
        'Channel URL found in query params : ',
        query.get('channelUrl'),
      );
      setYtChannelUrl(query.get('channelUrl'));
      fetchYTStats(query.get('channelUrl'));
    } else {
      setYtChannelUrl('');
      console.log('No channel URL present in route params');
    }
  }, [ytChannelUrl]);

  const handleSubmitChannelUrl = e => {
    e.preventDefault();
    navigate(`?channelUrl=${ytChannelUrl}`);
    fetchYTStats(ytChannelUrl);
  };
  const findChannelID = url => {
    axios
      .post(URL, {
        channelURL: `/.netlify/functions/scrapper?channelURL=${url}`,
      })
      .then(function(response) {
        console.log(response);
        return `https://www.youtube.com/channel/${response.body}`;
      })
      .catch(function(error) {
        console.log(error);
      });
  };
  const notify = () =>
    toast.error(
      "Hey! Currently we're supporting only YouTube channel links 💩",
    );

  const notifyContactFormSubmit = () => {
    toast.success(
      'Thank you for sharing your contact info 🦄 We will touch base real soon!',
    );
    setIsContactFormSubmitted(true);
  };

  const notifyContactFormError = () => {
    toast.success(
      'Looks like our systems taking a 💩. We are gonna fix this real quick!',
    );
    setIsContactFormSubmitted(true);
  };

  const notifyContactFormFilInError = () => {
    toast.warning(
      'Hmm 🤔.. Looks like you forgot to give us your contact info.',
    );
  };

  const fetchYTStats = url => {
    console.log('Fetching data for channel : ', url);
    if (validateURL(url.trim())) {
      console.log('Validating Channel URL');
      setIsLoading(true);
      setButtonState('loading');
      let URL =
        'https://us-central1-creatorstack-staging.cloudfunctions.net/get_guest_yt_channel_stats';
      if (
        url.includes('/c/') ||
        url.includes('/user/') ||
        url.includes('/watch?')
      ) {
        console.log('Scanning to find Channel ID');
        axios
          .post(`/.netlify/functions/scrapper?channelURL=${url.trim()}`, {
            channelURL: `/.netlify/functions/scrapper?channelURL=${url.trim()}`,
          })
          .then(function(response) {
            console.log(response.data);
            axios
              .post(URL, {
                url: `https://www.youtube.com/channel/${response.data}`,
              })
              .then(function(response) {
                console.log(response);
                let revenueDataMin = [];
                let revenueDataMax = [];
                let revenueDataDirect = [];
                let totalViews,
                  totalComments,
                  totalLikes,
                  totalDislikes,
                  totalFavourites;
                let engagements = [];
                let reactions = [];
                let comments = [];
                let labels = [];
                let x = 1200;
                let y = 1;

                // Iteration through every video and setting individual engagement metrices

                response.data.channel_videos.map(video => {
                  totalViews = totalComments = totalLikes = totalDislikes = totalFavourites = 0;
                  revenueDataMin.push(
                    ((video.statistics.viewCount * 1.36) / 1000).toFixed(2),
                  );
                  revenueDataMax.push(
                    ((video.statistics.viewCount * 3.4) / 1000).toFixed(2),
                  );
                  revenueDataDirect.push(
                    ((video.statistics.viewCount * 3.4) / (x / y)).toFixed(2),
                  );
                  console.log('view count', video.statistics.viewCount);
                  labels.push(video.snippet.title.substring(0, 0));
                  if (earningsMultiplier > 1.5 && earningsMultiplier < 5) {
                    y += earningsMultiplier;
                  } else {
                    y += 0.08982;
                  }
                  if (!isNaN(parseInt(video.statistics.viewCount))) {
                    totalViews =
                      totalViews + parseInt(video.statistics.viewCount);
                  }
                  if (!isNaN(parseInt(video.statistics.commentCount))) {
                    totalComments =
                      totalComments + parseInt(video.statistics.commentCount);
                  }
                  if (!isNaN(parseInt(video.statistics.likeCount))) {
                    totalLikes =
                      totalLikes + parseInt(video.statistics.likeCount);
                  }
                  if (!isNaN(parseInt(video.statistics.dislikeCount))) {
                    totalDislikes =
                      totalDislikes + parseInt(video.statistics.dislikeCount);
                  }
                  if (!isNaN(parseInt(video.statistics.favoriteCount))) {
                    totalFavourites =
                      totalFavourites +
                      parseInt(video.statistics.favoriteCount);
                  }
                  engagements.push(
                    totalLikes +
                      totalDislikes +
                      totalComments +
                      totalFavourites,
                  );
                  reactions.push(totalLikes + totalDislikes + totalFavourites);
                });
                // Set total revenue metrics to state
                let totalAdRevenueMax = revenueDataMax.reduce(function(
                  accumulator,
                  currentValue,
                ) {
                  return accumulator + currentValue;
                },
                0);
                let totalAdRevenueMin = revenueDataMin.reduce(function(
                  accumulator,
                  currentValue,
                ) {
                  return accumulator + currentValue;
                },
                0);
                let avgAdRevenueCalculated =
                  revenueDataMin.reduce(function(accumulator, currentValue) {
                    return parseInt(accumulator) + parseInt(currentValue);
                  }, 0) /
                    10 +
                  revenueDataMax.reduce(function(accumulator, currentValue) {
                    return parseInt(accumulator) + parseInt(currentValue);
                  }, 0) /
                    10;
                let calculatedAvgAdRevenue = avgAdRevenueCalculated / 2;
                let avgAdRevenue = calculatedAvgAdRevenue;
                let totalDirectRevenue = revenueDataDirect.reduce(function(
                  accumulator,
                  currentValue,
                ) {
                  return parseInt(accumulator) + parseInt(currentValue);
                },
                0);
                if (
                  response.data.channel_info.statistics.subscriberCount > 15000
                ) {
                  console.log(
                    'earnings multiplier',
                    +totalDirectRevenue / +calculatedAvgAdRevenue,
                  );
                  setEarningsMultiplier(
                    +totalDirectRevenue / +calculatedAvgAdRevenue,
                  );
                } else {
                  setEarningsMultiplier(
                    Math.floor(totalEngagement / 10) * 0.0001,
                  );
                }
                // Set channel level data
                setChannelTitle(
                  response.data.channel_videos[0].snippet.channelTitle,
                );
                setChannelAvatar(
                  response.data.channel_info.snippet.thumbnails.default.url,
                );

                // Calculating and preparing engagement metrices to set in state

                let totalEngagement =
                  totalLikes + totalDislikes + totalComments + totalFavourites;
                let totalReactions =
                  totalLikes + totalDislikes + totalFavourites;
                console.log(
                  'avg views : ',
                  response.data.channel_info.statistics.viewCount /
                    response.data.channel_info.statistics.videoCount,
                );
                setAvgViewCount(
                  +response.data.channel_info.statistics.viewCount /
                    +response.data.channel_info.statistics.videoCount,
                );
                console.log(
                  'video count',
                  response.data.channel_info.statistics.videoCount,
                );
                setSubscriberCount(
                  response.data.channel_info.statistics.subscriberCount,
                );
                setAvgCommentsPerVideo(
                  Math.floor(totalComments / engagements.length),
                );
                setAvgEngagementPerVideo(
                  Math.floor(totalEngagement / engagements.length),
                );
                setAvgReactionsPerVideo(
                  Math.floor(totalReactions / engagements.length),
                );
                // Calculating rate change of engagement metrices
                let engagementsRateArray, reactionsRateArray, commentsRateArray;
                engagementsRateArray = reactionsRateArray = commentsRateArray = [];
                for (let i = 0; i < engagements.length - 1; i++) {
                  engagementsRateArray.push(
                    engagements[i + 1] - engagements[i],
                  );
                }
                setAvgChangeInEngagementPerVideo(
                  engagementsRateArray.reduce(function(
                    accumulator,
                    currentValue,
                  ) {
                    return accumulator + currentValue;
                  },
                  0) /
                    engagementsRateArray.length /
                    100,
                );
                for (let i = 0; i < reactions.length - 1; i++) {
                  reactionsRateArray.push(reactions[i + 1] - reactions[i]);
                }
                setAvgChangeInReactionsPerVideo(
                  reactionsRateArray.reduce(function(
                    accumulator,
                    currentValue,
                  ) {
                    return accumulator + currentValue;
                  },
                  0) /
                    reactionsRateArray.length /
                    100,
                );
                for (let i = 0; i < comments.length - 1; i++) {
                  commentsRateArray.push(comments[i + 1] - comments[i]);
                }
                setAvgChangeInCommentsPerVideo(
                  commentsRateArray.reduce(function(accumulator, currentValue) {
                    return accumulator + currentValue;
                  }, 0) /
                    commentsRateArray.length /
                    100,
                );
                // Set loading state false and scroll a bit down to give focus on the result
                setIsLoading(false);
                console.log('avgview count', avgViewCount);
                scrollTo('#analytics-container');
                // Draw the performance area chart
                let ctx = document.getElementById('performance-chart');
                let ctx1 = document.getElementById('fan-analysis-chart');
                let ctx2 = document.getElementById('earnings-analysis-chart');
                if (width < 700) {
                  ctx.height = 360;
                }
                const config = {
                  type: 'line',
                  data: {
                    labels: labels,
                    datasets: [
                      {
                        label: 'Minimum possible ad revenue',
                        backgroundColor: '#EAB543',
                        borderColor: '#f5cd79',
                        data: revenueDataMin,
                        pointRadius: 2,
                        fill: true,
                      },
                      {
                        label: 'Maximum possible ad revenue',
                        fill: true,
                        backgroundColor: '#FC427B',
                        borderColor: '#c44569',
                        data: revenueDataMax,
                        pointRadius: 2,
                      },
                      {
                        label: 'Possible Direct To Fan revenue',
                        fill: true,
                        backgroundColor: '#3dc1d3',
                        borderColor: '#3dc1d3',
                        data: revenueDataDirect,
                        pointRadius: 2,
                      },
                    ],
                  },
                  options: {
                    responsive: true,
                    maintainAspectRatio: width < 700 ? false : true,
                    legend: {
                      display: true,
                      position: 'bottom',
                      labels: {
                        fontColor: '#fafafa',
                        fontFamily: "'Avenir', sans-serif",
                        boxWidth: 12,
                        padding: 16,
                      },
                      align: 'center',
                    },
                    title: {
                      display: false,
                      text: 'Performance summary',
                    },
                    // tooltips: {
                    //   mode: 'index',
                    //   intersect: false,
                    // },
                    // hover: {
                    //   mode: 'nearest',
                    //   intersect: true,
                    // },
                    scales: {
                      xAxes: [
                        {
                          ticks: {
                            // Include a dollar sign in the ticks
                            callback: function(value, index, values) {
                              return index + 1;
                            },
                          },
                        },
                      ],
                      yAxes: [
                        {
                          ticks: {
                            // Include a dollar sign in the ticks
                            callback: function(value, index, values) {
                              return '$' + value;
                            },
                          },
                        },
                      ],
                    },
                    point: { radius: 0 },
                  },
                };
                setTrueFanCount(
                  Math.ceil(
                    response.data.channel_info.statistics.subscriberCount *
                      0.013,
                  ),
                );
                setSuperFanCount(
                  Math.ceil(
                    response.data.channel_info.statistics.subscriberCount *
                      0.0012,
                  ),
                );
                setPerformanceChart(new Chart(ctx, config));
                setFanAnalysisChart(
                  new Chart(ctx1, {
                    type: 'doughnut',
                    data: {
                      datasets: [
                        {
                          data: [
                            Math.ceil(
                              response.data.channel_info.statistics
                                .subscriberCount * 0.013,
                            ),
                            Math.ceil(
                              response.data.channel_info.statistics
                                .subscriberCount * 0.0012,
                            ),
                          ],
                          backgroundColor: [
                            chartColors.yellow,
                            chartColors.green,
                          ],
                        },
                      ],

                      // These labels appear in the legend and in the tooltips when hovering different arcs
                      labels: ['TrueFans', 'SuperFans'],
                    },
                    options: {
                      elements: {
                        arc: {
                          borderWidth: 0,
                        },
                      },
                      legend: {
                        display: true,
                        position: 'bottom',
                        labels: {
                          fontColor: '#fafafa',
                          fontFamily: "'Avenir', sans-serif",
                          boxWidth: 12,
                          padding: 16,
                        },
                        align: 'center',
                      },
                    },
                  }),
                );
                setEarningsAnalysisChart(
                  new Chart(ctx2, {
                    type: 'doughnut',
                    data: {
                      datasets: [
                        {
                          data: [
                            Math.ceil(avgAdRevenue),
                            Math.ceil(totalDirectRevenue),
                          ],
                          backgroundColor: [
                            chartColors.red,
                            chartColors.yellow,
                          ],
                        },
                      ],

                      // These labels appear in the legend and in the tooltips when hovering different arcs
                      labels: ['Ad revenue', 'Direct engagement'],
                    },
                    options: {
                      elements: {
                        arc: {
                          borderWidth: 0,
                        },
                      },
                      legend: {
                        display: true,
                        position: 'bottom',
                        labels: {
                          fontColor: '#fafafa',
                          fontFamily: "'Avenir', sans-serif",
                          boxWidth: 12,
                          padding: 16,
                        },
                        align: 'center',
                      },
                      tooltips: {
                        callbacks: {
                          // this callback is used to create the tooltip label
                          label: function(tooltipItem, data) {
                            // get the data label and data value to display
                            // convert the data value to local string so it uses a comma seperated number
                            var dataLabel = data.labels[tooltipItem.index];
                            var value =
                              ': $' +
                              data.datasets[tooltipItem.datasetIndex].data[
                                tooltipItem.index
                              ].toLocaleString();

                            // make this isn't a multi-line label (e.g. [["label 1 - line 1, "line 2, ], [etc...]])
                            if (Chart.helpers.isArray(dataLabel)) {
                              // show value on first line of multiline label
                              // need to clone because we are changing the value
                              dataLabel = dataLabel.slice();
                              dataLabel[0] += value;
                            } else {
                              dataLabel += value;
                            }

                            // return the text to display on the tooltip
                            return dataLabel;
                          },
                        },
                      },
                    },
                  }),
                );
                setButtonState('finished');

                setButtonState('');
                setShowChannelInfoCard(true);
              })
              .catch(function(error) {
                setButtonState('');
                setIsStatsVisible(false);
                notify();
                console.log(error);
              });
          })
          .catch(function(error) {
            console.log(error);
            notify();
          });
      }
      if (url.includes('/channel/')) {
        let formattedURL = url.trim();
        if (url.substr(url.length - 1) === '/') {
          formattedURL = url.slice(0, -1);
          console.log('new url', formattedURL);
        }
        axios
          .post(URL, {
            url: formattedURL,
          })
          .then(function(response) {
            let revenueDataMin = [];
            let revenueDataMax = [];
            let revenueDataDirect = [];
            let totalViews,
              totalComments,
              totalLikes,
              totalDislikes,
              totalFavourites;
            totalViews = totalComments = totalLikes = totalDislikes = totalFavourites = 0;
            let engagements = [];
            let reactions = [];
            let comments = [];
            let labels = [];
            let x = 1200;
            let y = 1;

            // Iteration through every video and setting individual engagement metrices

            response.data.channel_videos.map(video => {
              revenueDataMin.push(
                ((video.statistics.viewCount * 1.36) / 1000).toFixed(2),
              );
              revenueDataMax.push(
                ((video.statistics.viewCount * 3.4) / 1000).toFixed(2),
              );
              revenueDataDirect.push(
                ((video.statistics.viewCount * 3.4) / (x / y)).toFixed(2),
              );
              labels.push(video.snippet.title.substring(0, 0));
              if (earningsMultiplier > 1.5 && earningsMultiplier < 5) {
                y += earningsMultiplier;
              } else {
                y += 0.08982;
              }
              if (!isNaN(parseInt(video.statistics.viewCount))) {
                totalViews = totalViews + parseInt(video.statistics.viewCount);
              }
              if (!isNaN(parseInt(video.statistics.commentCount))) {
                totalComments =
                  totalComments + parseInt(video.statistics.commentCount);
              }
              if (!isNaN(parseInt(video.statistics.likeCount))) {
                totalLikes = totalLikes + parseInt(video.statistics.likeCount);
              }
              if (!isNaN(parseInt(video.statistics.dislikeCount))) {
                totalDislikes =
                  totalDislikes + parseInt(video.statistics.dislikeCount);
              }
              if (!isNaN(parseInt(video.statistics.favoriteCount))) {
                totalFavourites =
                  totalFavourites + parseInt(video.statistics.favoriteCount);
              }
              console.log(
                'like count',
                typeof parseInt(video.statistics.dislikeCount),
              );
              engagements.push(
                totalLikes + totalDislikes + totalComments + totalFavourites,
              );
              reactions.push(totalLikes + totalDislikes + totalFavourites);
              comments.push(totalComments);
            });
            console.log(
              revenueDataMax,
              revenueDataMin,
              'Views : ',
              totalViews,
              'Comments : ',
              totalComments,
              'Likes : ',
              totalLikes,
              'Dislikes : ',
              totalDislikes,
              'Favourites : ',
              totalFavourites,
            );
            // Set total revenue metrics to state
            let totalAdRevenueMax = revenueDataMax.reduce(function(
              accumulator,
              currentValue,
            ) {
              return accumulator + currentValue;
            },
            0);
            let totalAdRevenueMin = revenueDataMin.reduce(function(
              accumulator,
              currentValue,
            ) {
              return accumulator + currentValue;
            },
            0);
            let avgAdRevenueCalculated =
              revenueDataMin.reduce(function(accumulator, currentValue) {
                return parseInt(accumulator) + parseInt(currentValue);
              }, 0) /
                10 +
              revenueDataMax.reduce(function(accumulator, currentValue) {
                return parseInt(accumulator) + parseInt(currentValue);
              }, 0) /
                10;
            let calculatedAvgAdRevenue = avgAdRevenueCalculated / 2;
            let avgAdRevenue = calculatedAvgAdRevenue;
            console.log('revenueDataDirect', revenueDataDirect);
            let totalDirectRevenue = revenueDataDirect.reduce(function(
              accumulator,
              currentValue,
            ) {
              return +accumulator + +currentValue;
            },
            0);
            if (response.data.channel_info.statistics.subscriberCount > 15000) {
              console.log(
                'earnings multiplier',
                +totalDirectRevenue / +calculatedAvgAdRevenue,
              );
              setEarningsMultiplier(
                +totalDirectRevenue / +calculatedAvgAdRevenue,
              );
            } else {
              setEarningsMultiplier(Math.floor(totalEngagement / 10) * 0.0001);
            }
            // Set channel level data
            setChannelTitle(
              response.data.channel_videos[0].snippet.channelTitle,
            );
            setChannelAvatar(
              response.data.channel_info.snippet.thumbnails.default.url,
            );

            // Calculating and preparing engagement metrices to set in state

            let totalEngagement =
              totalLikes + totalDislikes + totalComments + totalFavourites;
            let totalReactions = totalLikes + totalDislikes + totalFavourites;

            setAvgViewCount(
              Math.floor(
                +response.data.channel_info.statistics.viewCount /
                  +response.data.channel_info.statistics.videoCount,
              ),
            );

            setSubscriberCount(
              response.data.channel_info.statistics.subscriberCount,
            );

            setAvgCommentsPerVideo(
              Math.floor(totalComments / engagements.length),
            );
            setAvgEngagementPerVideo(
              Math.floor(totalEngagement / engagements.length),
            );
            setAvgReactionsPerVideo(
              Math.floor(totalReactions / engagements.length),
            );

            // Calculating rate change of engagement metrices
            let engagementsRateArray, reactionsRateArray, commentsRateArray;
            engagementsRateArray = reactionsRateArray = commentsRateArray = [];
            for (let i = 0; i < engagements.length - 1; i++) {
              engagementsRateArray.push(engagements[i + 1] - engagements[i]);
            }
            setAvgChangeInEngagementPerVideo(
              engagementsRateArray.reduce(function(accumulator, currentValue) {
                return accumulator + currentValue;
              }, 0) /
                engagementsRateArray.length /
                100,
            );
            for (let i = 0; i < reactions.length - 1; i++) {
              reactionsRateArray.push(reactions[i + 1] - reactions[i]);
            }
            setAvgChangeInReactionsPerVideo(
              reactionsRateArray.reduce(function(accumulator, currentValue) {
                return accumulator + currentValue;
              }, 0) /
                reactionsRateArray.length /
                100,
            );
            for (let i = 0; i < comments.length - 1; i++) {
              commentsRateArray.push(comments[i + 1] - comments[i]);
            }
            setAvgChangeInCommentsPerVideo(
              commentsRateArray.reduce(function(accumulator, currentValue) {
                return accumulator + currentValue;
              }, 0) /
                commentsRateArray.length /
                100,
            );
            // Set loading state false and scroll a bit down to give focus on the result
            setIsLoading(false);
            scrollTo('#analytics-container');
            console.log('avgview count', avgViewCount);
            // Draw the performance area chart
            let ctx = document.getElementById('performance-chart');
            let ctx1 = document.getElementById('fan-analysis-chart');
            let ctx2 = document.getElementById('earnings-analysis-chart');
            const config = {
              type: 'line',
              data: {
                labels: labels,
                datasets: [
                  {
                    label: 'Ad revenue Minimum',
                    backgroundColor: '#EAB543',
                    borderColor: '#f5cd79',
                    data: revenueDataMin,
                    pointRadius: 2,
                    fill: true,
                  },
                  {
                    label: 'Ad revenue Maximum',
                    fill: true,
                    backgroundColor: '#FC427B',
                    borderColor: '#c44569',
                    data: revenueDataMax,
                    pointRadius: 2,
                  },
                  {
                    label: 'Direct to fan engagement',
                    fill: true,
                    backgroundColor: '#3dc1d3',
                    borderColor: '#3dc1d3',
                    data: revenueDataDirect,
                    pointRadius: 2,
                  },
                ],
              },
              options: {
                responsive: true,
                legend: {
                  display: true,
                  position: 'bottom',
                  labels: {
                    fontColor: '#fafafa',
                    fontFamily: "'Avenir', sans-serif",
                    boxWidth: 12,
                    padding: 16,
                  },
                  align: 'center',
                },
                title: {
                  display: false,
                  text: 'Performance summary',
                },
                // tooltips: {
                //   mode: 'index',
                //   intersect: false,
                // },
                // hover: {
                //   mode: 'nearest',
                //   intersect: true,
                // },
                scales: {
                  xAxes: [
                    {
                      ticks: {
                        // Include a dollar sign in the ticks
                        callback: function(value, index, values) {
                          return index + 1;
                        },
                      },
                    },
                  ],
                  yAxes: [
                    {
                      ticks: {
                        // Include a dollar sign in the ticks
                        callback: function(value, index, values) {
                          return '$' + value;
                        },
                      },
                    },
                  ],
                },
                point: { radius: 0 },
              },
            };
            setTrueFanCount(
              Math.ceil(
                response.data.channel_info.statistics.subscriberCount * 0.013,
              ),
            );
            setSuperFanCount(
              Math.ceil(
                response.data.channel_info.statistics.subscriberCount * 0.0012,
              ),
            );
            setPerformanceChart(new Chart(ctx, config));
            setFanAnalysisChart(
              new Chart(ctx1, {
                type: 'doughnut',
                data: {
                  datasets: [
                    {
                      data: [
                        Math.ceil(
                          response.data.channel_info.statistics
                            .subscriberCount * 0.013,
                        ),
                        Math.ceil(
                          response.data.channel_info.statistics
                            .subscriberCount * 0.0012,
                        ),
                      ],
                      backgroundColor: [chartColors.yellow, chartColors.green],
                    },
                  ],

                  // These labels appear in the legend and in the tooltips when hovering different arcs
                  labels: ['Most loyal fans', 'Superfans'],
                },
                options: {
                  elements: {
                    arc: {
                      borderWidth: 0,
                    },
                  },
                  legend: {
                    display: true,
                    position: 'bottom',
                    labels: {
                      fontColor: '#fafafa',
                      fontFamily: "'Avenir', sans-serif",
                      boxWidth: 12,
                      padding: 16,
                    },
                    align: 'center',
                  },
                },
              }),
            );
            setEarningsAnalysisChart(
              new Chart(ctx2, {
                type: 'doughnut',
                data: {
                  datasets: [
                    {
                      data: [
                        Math.ceil(avgAdRevenue),
                        Math.ceil(totalDirectRevenue),
                      ],
                      backgroundColor: [chartColors.red, chartColors.yellow],
                    },
                  ],

                  // These labels appear in the legend and in the tooltips when hovering different arcs
                  labels: ['Ad revenue', 'Direct engagement'],
                },
                options: {
                  elements: {
                    arc: {
                      borderWidth: 0,
                    },
                  },
                  legend: {
                    display: true,
                    position: 'bottom',
                    labels: {
                      fontColor: '#fafafa',
                      fontFamily: "'Avenir', sans-serif",
                      boxWidth: 12,
                      padding: 16,
                    },
                    align: 'center',
                  },
                  tooltips: {
                    callbacks: {
                      // this callback is used to create the tooltip label
                      label: function(tooltipItem, data) {
                        // get the data label and data value to display
                        // convert the data value to local string so it uses a comma seperated number
                        var dataLabel = data.labels[tooltipItem.index];
                        var value =
                          ': $' +
                          data.datasets[tooltipItem.datasetIndex].data[
                            tooltipItem.index
                          ].toLocaleString();

                        // make this isn't a multi-line label (e.g. [["label 1 - line 1, "line 2, ], [etc...]])
                        if (Chart.helpers.isArray(dataLabel)) {
                          // show value on first line of multiline label
                          // need to clone because we are changing the value
                          dataLabel = dataLabel.slice();
                          dataLabel[0] += value;
                        } else {
                          dataLabel += value;
                        }

                        // return the text to display on the tooltip
                        return dataLabel;
                      },
                    },
                  },
                },
              }),
            );
            setButtonState('finished');

            setButtonState('');
            setShowChannelInfoCard(true);
          })
          .catch(function(error) {
            setButtonState('');
            setIsStatsVisible(false);
            notify();
            console.log(error);
          });
      } else {
        {
          console.log('Scanning to find Channel ID');
          axios
            .post(`/.netlify/functions/scrapper?channelURL=${url.trim()}`, {
              channelURL: `/.netlify/functions/scrapper?channelURL=${url.trim()}`,
            })
            .then(function(response) {
              console.log(response.data);
              axios
                .post(URL, {
                  url: `https://www.youtube.com/channel/${response.data}`,
                })
                .then(function(response) {
                  console.log(response);
                  let revenueDataMin = [];
                  let revenueDataMax = [];
                  let revenueDataDirect = [];
                  let totalViews,
                    totalComments,
                    totalLikes,
                    totalDislikes,
                    totalFavourites;
                  totalLikes = totalComments = totalDislikes = totalFavourites = 0;
                  let engagements = [];
                  let reactions = [];
                  let comments = [];
                  let labels = [];
                  let x = 1200;
                  let y = 1;

                  // Iteration through every video and setting individual engagement metrices

                  response.data.channel_videos.map(video => {
                    revenueDataMin.push(
                      ((video.statistics.viewCount * 1.36) / 1000).toFixed(2),
                    );
                    revenueDataMax.push(
                      ((video.statistics.viewCount * 3.4) / 1000).toFixed(2),
                    );
                    revenueDataDirect.push(
                      ((video.statistics.viewCount * 3.4) / (x / y)).toFixed(2),
                    );
                    console.log('view count', video.statistics.viewCount);
                    labels.push(video.snippet.title.substring(0, 0));
                    if (earningsMultiplier > 1.5 && earningsMultiplier < 5) {
                      y += earningsMultiplier;
                    } else {
                      y += 0.08982;
                    }
                    if (!isNaN(parseInt(video.statistics.viewCount))) {
                      totalViews =
                        totalViews + parseInt(video.statistics.viewCount);
                    }
                    if (!isNaN(parseInt(video.statistics.commentCount))) {
                      totalComments =
                        totalComments + parseInt(video.statistics.commentCount);
                    }
                    if (!isNaN(parseInt(video.statistics.likeCount))) {
                      totalLikes += +video.statistics.likeCount;
                      console.log('totalLikes', totalLikes);
                    }
                    if (!isNaN(parseInt(video.statistics.dislikeCount))) {
                      totalDislikes =
                        totalDislikes + parseInt(video.statistics.dislikeCount);
                    }
                    if (!isNaN(parseInt(video.statistics.favoriteCount))) {
                      totalFavourites =
                        totalFavourites +
                        parseInt(video.statistics.favoriteCount);
                    }
                    engagements.push(
                      totalLikes +
                        totalDislikes +
                        totalComments +
                        totalFavourites,
                    );
                    reactions.push(
                      totalLikes + totalDislikes + totalFavourites,
                    );
                  });
                  // Set total revenue metrics to state
                  let totalAdRevenueMax = revenueDataMax.reduce(function(
                    accumulator,
                    currentValue,
                  ) {
                    return accumulator + currentValue;
                  },
                  0);
                  let totalAdRevenueMin = revenueDataMin.reduce(function(
                    accumulator,
                    currentValue,
                  ) {
                    return accumulator + currentValue;
                  },
                  0);
                  let avgAdRevenueCalculated =
                    revenueDataMin.reduce(function(accumulator, currentValue) {
                      return parseInt(accumulator) + parseInt(currentValue);
                    }, 0) /
                      10 +
                    revenueDataMax.reduce(function(accumulator, currentValue) {
                      return parseInt(accumulator) + parseInt(currentValue);
                    }, 0) /
                      10;
                  let calculatedAvgAdRevenue = avgAdRevenueCalculated / 2;
                  let avgAdRevenue = calculatedAvgAdRevenue;
                  let totalDirectRevenue = revenueDataDirect.reduce(function(
                    accumulator,
                    currentValue,
                  ) {
                    return parseInt(accumulator) + parseInt(currentValue);
                  },
                  0);
                  if (
                    response.data.channel_info.statistics.subscriberCount >
                    15000
                  ) {
                    console.log(
                      'earnings multiplier',
                      +totalDirectRevenue / +calculatedAvgAdRevenue,
                    );
                    setEarningsMultiplier(
                      +totalDirectRevenue / +calculatedAvgAdRevenue,
                    );
                  } else {
                    setEarningsMultiplier(
                      Math.floor(totalEngagement / 10) * 0.0001,
                    );
                  }
                  // Set channel level data
                  setChannelTitle(
                    response.data.channel_videos[0].snippet.channelTitle,
                  );
                  setChannelAvatar(
                    response.data.channel_info.snippet.thumbnails.default.url,
                  );

                  // Calculating and preparing engagement metrices to set in state

                  let totalEngagement =
                    totalLikes +
                    totalDislikes +
                    totalComments +
                    totalFavourites;
                  console.log('Total Engagement', totalEngagement);
                  let totalReactions =
                    totalLikes + totalDislikes + totalFavourites;
                  console.log(
                    'avg views : ',
                    response.data.channel_info.statistics.viewCount /
                      response.data.channel_info.statistics.videoCount,
                  );
                  setAvgViewCount(
                    +response.data.channel_info.statistics.viewCount /
                      +response.data.channel_info.statistics.videoCount,
                  );
                  console.log(
                    'video count',
                    response.data.channel_info.statistics.videoCount,
                  );
                  setSubscriberCount(
                    response.data.channel_info.statistics.subscriberCount,
                  );
                  setAvgCommentsPerVideo(
                    Math.floor(totalComments / engagements.length),
                  );
                  setAvgEngagementPerVideo(
                    Math.floor(totalEngagement / engagements.length),
                  );
                  setAvgReactionsPerVideo(
                    Math.floor(totalReactions / engagements.length),
                  );
                  // Calculating rate change of engagement metrices
                  let engagementsRateArray,
                    reactionsRateArray,
                    commentsRateArray;
                  engagementsRateArray = reactionsRateArray = commentsRateArray = [];
                  for (let i = 0; i < engagements.length - 1; i++) {
                    engagementsRateArray.push(
                      engagements[i + 1] - engagements[i],
                    );
                  }
                  setAvgChangeInEngagementPerVideo(
                    engagementsRateArray.reduce(function(
                      accumulator,
                      currentValue,
                    ) {
                      return accumulator + currentValue;
                    },
                    0) /
                      engagementsRateArray.length /
                      100,
                  );
                  for (let i = 0; i < reactions.length - 1; i++) {
                    reactionsRateArray.push(reactions[i + 1] - reactions[i]);
                  }
                  setAvgChangeInReactionsPerVideo(
                    reactionsRateArray.reduce(function(
                      accumulator,
                      currentValue,
                    ) {
                      return accumulator + currentValue;
                    },
                    0) /
                      reactionsRateArray.length /
                      100,
                  );
                  for (let i = 0; i < comments.length - 1; i++) {
                    commentsRateArray.push(comments[i + 1] - comments[i]);
                  }
                  setAvgChangeInCommentsPerVideo(
                    commentsRateArray.reduce(function(
                      accumulator,
                      currentValue,
                    ) {
                      return accumulator + currentValue;
                    },
                    0) /
                      commentsRateArray.length /
                      100,
                  );
                  // Set loading state false and scroll a bit down to give focus on the result
                  setIsLoading(false);
                  console.log('avgview count', +avgViewCount);
                  scrollTo('#analytics-container');
                  // Draw the performance area chart
                  let ctx = document.getElementById('performance-chart');
                  let ctx1 = document.getElementById('fan-analysis-chart');
                  let ctx2 = document.getElementById('earnings-analysis-chart');
                  if (width < 700) {
                    ctx.height = 360;
                  }
                  const config = {
                    type: 'line',
                    data: {
                      labels: labels,
                      datasets: [
                        {
                          label: 'Minimum possible ad revenue',
                          backgroundColor: '#EAB543',
                          borderColor: '#f5cd79',
                          data: revenueDataMin,
                          pointRadius: 2,
                          fill: true,
                        },
                        {
                          label: 'Maximum possible ad revenue',
                          fill: true,
                          backgroundColor: '#FC427B',
                          borderColor: '#c44569',
                          data: revenueDataMax,
                          pointRadius: 2,
                        },
                        {
                          label: 'Possible Direct To Fan revenue',
                          fill: true,
                          backgroundColor: '#3dc1d3',
                          borderColor: '#3dc1d3',
                          data: revenueDataDirect,
                          pointRadius: 2,
                        },
                      ],
                    },
                    options: {
                      responsive: true,
                      maintainAspectRatio: width < 700 ? false : true,
                      legend: {
                        display: true,
                        position: 'bottom',
                        labels: {
                          fontColor: '#fafafa',
                          fontFamily: "'Avenir', sans-serif",
                          boxWidth: 12,
                          padding: 16,
                        },
                        align: 'center',
                      },
                      title: {
                        display: false,
                        text: 'Performance summary',
                      },
                      // tooltips: {
                      //   mode: 'index',
                      //   intersect: false,
                      // },
                      // hover: {
                      //   mode: 'nearest',
                      //   intersect: true,
                      // },
                      scales: {
                        xAxes: [
                          {
                            ticks: {
                              // Include a dollar sign in the ticks
                              callback: function(value, index, values) {
                                return index + 1;
                              },
                            },
                          },
                        ],
                        yAxes: [
                          {
                            ticks: {
                              // Include a dollar sign in the ticks
                              callback: function(value, index, values) {
                                return '$' + value;
                              },
                            },
                          },
                        ],
                      },
                      point: { radius: 0 },
                    },
                  };
                  setTrueFanCount(
                    Math.ceil(
                      response.data.channel_info.statistics.subscriberCount *
                        0.013,
                    ),
                  );
                  setSuperFanCount(
                    Math.ceil(
                      response.data.channel_info.statistics.subscriberCount *
                        0.0012,
                    ),
                  );
                  setPerformanceChart(new Chart(ctx, config));
                  setFanAnalysisChart(
                    new Chart(ctx1, {
                      type: 'doughnut',
                      data: {
                        datasets: [
                          {
                            data: [
                              Math.ceil(
                                response.data.channel_info.statistics
                                  .subscriberCount * 0.013,
                              ),
                              Math.ceil(
                                response.data.channel_info.statistics
                                  .subscriberCount * 0.0012,
                              ),
                            ],
                            backgroundColor: [
                              chartColors.yellow,
                              chartColors.green,
                            ],
                          },
                        ],

                        // These labels appear in the legend and in the tooltips when hovering different arcs
                        labels: ['TrueFans', 'SuperFans'],
                      },
                      options: {
                        elements: {
                          arc: {
                            borderWidth: 0,
                          },
                        },
                        legend: {
                          display: true,
                          position: 'bottom',
                          labels: {
                            fontColor: '#fafafa',
                            fontFamily: "'Avenir', sans-serif",
                            boxWidth: 12,
                            padding: 16,
                          },
                          align: 'center',
                        },
                      },
                    }),
                  );
                  setEarningsAnalysisChart(
                    new Chart(ctx2, {
                      type: 'doughnut',
                      data: {
                        datasets: [
                          {
                            data: [
                              Math.ceil(avgAdRevenue),
                              Math.ceil(totalDirectRevenue),
                            ],
                            backgroundColor: [
                              chartColors.red,
                              chartColors.yellow,
                            ],
                          },
                        ],

                        // These labels appear in the legend and in the tooltips when hovering different arcs
                        labels: ['Ad revenue', 'Direct engagement'],
                      },
                      options: {
                        elements: {
                          arc: {
                            borderWidth: 0,
                          },
                        },
                        legend: {
                          display: true,
                          position: 'bottom',
                          labels: {
                            fontColor: '#fafafa',
                            fontFamily: "'Avenir', sans-serif",
                            boxWidth: 12,
                            padding: 16,
                          },
                          align: 'center',
                        },
                        tooltips: {
                          callbacks: {
                            // this callback is used to create the tooltip label
                            label: function(tooltipItem, data) {
                              // get the data label and data value to display
                              // convert the data value to local string so it uses a comma seperated number
                              var dataLabel = data.labels[tooltipItem.index];
                              var value =
                                ': $' +
                                data.datasets[tooltipItem.datasetIndex].data[
                                  tooltipItem.index
                                ].toLocaleString();

                              // make this isn't a multi-line label (e.g. [["label 1 - line 1, "line 2, ], [etc...]])
                              if (Chart.helpers.isArray(dataLabel)) {
                                // show value on first line of multiline label
                                // need to clone because we are changing the value
                                dataLabel = dataLabel.slice();
                                dataLabel[0] += value;
                              } else {
                                dataLabel += value;
                              }

                              // return the text to display on the tooltip
                              return dataLabel;
                            },
                          },
                        },
                      },
                    }),
                  );
                  setButtonState('finished');

                  setButtonState('');
                  setShowChannelInfoCard(true);
                })
                .catch(function(error) {
                  setButtonState('');
                  setIsStatsVisible(false);
                  notify();
                  console.log(error);
                });
            })
            .catch(function(error) {
              console.log(error);
              notify();
            });
        }
      }
    } else {
      notify();
      setButtonState('');
    }
  };

  const handleYTChannelURLChange = useCallback(event => {
    setYtChannelUrl(event.target.value);
  }, []);

  const validateURL = useCallback(str => {
    let pattern = new RegExp(
      '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$',
      'i',
    ); // fragment locator
    return !!pattern.test(str);
  });

  // const encode = data => {
  //   return Object.keys(data)
  //     .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
  //     .join('&');
  // };

  const createContact = data => {
    return fetch('/.netlify/functions/contacts-create', {
      method: 'POST',
      body: JSON.stringify(data),
    })
      .then(response => response.json())
      .catch(error => error.body);
  };

  const handleSubmit = e => {
    e.preventDefault();
    if (contactFormData.email || contactFormData.phone) {
      setContactFormSubmissionProcessing(true);
      createContact({
        channelData: {
          channelURL: ytChannelUrl,
          channelTitle: channelTitle,
          subscriberCount: subscriberCount,
          avgViewCount: avgViewCount,
          avgCommentsPerVideo: avgCommentsPerVideo,
          avgEngagementPerVideo: avgEngagementPerVideo,
          avgReactionsPerVideo: avgReactionsPerVideo,
        },
        ...contactFormData,
      })
        .then(response => {
          console.log('API response', response);
          sendSlackNotification();
          notifyContactFormSubmit();
          setContactFormSubmissionProcessing(false);
        })
        .catch(error => {
          console.log('API error', error);
          notifyContactFormError();
          setContactFormSubmissionProcessing(false);
        });
    } else {
      notifyContactFormFilInError();
    }
  };

  const sendSlackNotification = () => {
    fetch('/.netlify/functions/slack', {
      method: 'POST',
      body: JSON.stringify({
        text: `👋 Hello! Got a new _*insights contact form submission*_ from \`${contactFormData.email}\`. <https://shorturl.at/lyMV3|Click here> for more details`,
      }),
    })
      .then(response => {
        if (!response.ok) {
          return response.text().then(err => {
            throw err;
          });
        }
      })
      .then(
        response => console.log(response),
        // update state from loading to complete
      )
      .catch(err => console.log(err));
  };

  const handleChange = e =>
    setContactFormData({ ...contactFormData, [e.target.name]: e.target.value });

  const kFormatter = num => {
    if (Math.abs(num) < 999) {
      return Math.sign(num) * Math.abs(num);
    } else if (Math.abs(num) > 999 && Math.abs(num) < 999999) {
      return Math.sign(num) * (Math.abs(num) / 1000).toFixed(2) + 'K';
    } else {
      return Math.sign(num) * (Math.abs(num) / 1000000).toFixed(2) + 'M';
    }
  };

  return (
    <>
      <Helmet>
        <html lang="en" />
        <title>Creator Insights - by CreatorStack</title>
        <meta name="description" content="" />
      </Helmet>
      <div className="container">
        <div className="header">
          <h1 className="header__heading">
            Creator <span className="header__heading--accent">insights</span>
          </h1>
          <div className="header-branding">
            <p className="header-branding__text">powered by </p>{' '}
            <a target="_blank" href="https://creatorstack.co">
              <img
                className="header-branding__logo"
                alt="CreatorStack Logo"
                src="https://creatorstack.s3.amazonaws.com/navbar-logo_2.png"
              />
            </a>
          </div>
        </div>
        <div className="channel-link-input-form-container">
          <form
            onSubmit={handleSubmitChannelUrl}
            className="form channel-link-input-form"
          >
            <p>Let's find your True Earning Potential</p>
            <div className="input-container input-container--group">
              {/* Grid layout will work here maintaining the arrangement here */}
              <input
                className="input channel-url-input"
                onChange={handleYTChannelURLChange}
                type="url"
                placeholder="Enter the link to your YouTube channel"
                value={ytChannelUrl}
              />

              <Button
                css={css`
                  width: 100% !important;
                  &:hover {
                    box-shadow: none;
                  }
                  @media (min-width: 700px) {
                    max-height: 56px;
                    max-width: 200px;
                  }
                `}
                bgColor="#02c39a"
                bgLoading="#02c39a"
                bgLoadingBehind="#011d28"
                onClick={handleSubmitChannelUrl}
                className="button channel-url-submit-button"
                state={buttonState}
              >
                Analyze
              </Button>
            </div>
          </form>
        </div>

        {!isLoading ? (
          <>
            <div
              className="channel-analytics-container"
              id="analytics-container"
            >
              <section className="channel-performace-summary">
                <div className="channel-performance-summary__header">
                  <div
                    css={css`
                      margin: 1rem 0;
                      @media (min-width: 700px) {
                        display: flex;
                        flex-flow: row wrap;
                      }
                    `}
                  >
                    <h4
                      css={css`
                        margin: 0.8rem 0;
                      `}
                      className="channel-performance-summary__heading"
                    >
                      Let's take a peek under the hood.{' '}
                    </h4>
                    <p
                      css={css`
                        font-size: 1.2rem;
                        font-weight: 400;
                        margin-top: 0.8rem;
                        margin-bottom: 0.8rem;
                        width: 100%;
                        font-family: Avenir, sans-serif;
                        @media (min-width: 700px) {
                          font-size: 1rem;
                        }
                      `}
                    >
                      We analyzed your last 10 videos to understand your True
                      Earning Potential.
                    </p>
                  </div>
                  <div
                    css={css`
                      display: flex;
                      flex-flow: row wrap;
                      justify-content: flex-end;
                    `}
                  >
                    <div className="analytics-timeline-container">
                      <div
                        onClick={() =>
                          setIsTimelineOptionsVisible(!isTimelineOptionsVisible)
                        }
                        className="active-timeline"
                      >
                        <UilVideo
                          size="24"
                          color="#02C39A"
                          css={css`
                            margin-right: 0.6rem;
                          `}
                        />{' '}
                        Last {selectedTimeline}{' '}
                        <UilAngleDown
                          size="32"
                          css={css`
                            margin-left: 1.6rem;
                            @media (min-width: 1441px) {
                              margin-left: 1.2rem;
                            }
                          `}
                          color="#02C39A"
                        />
                      </div>
                      <div
                        className="timeline-options-container"
                        css={
                          isTimelineOptionsVisible
                            ? css`
                                display: block;
                              `
                            : css`
                                display: none;
                              `
                        }
                      >
                        <ul
                          className="timeline-options"
                          css={css`
                            padding: 0;
                            margin: 0;
                          `}
                        >
                          <li>
                            <button
                              onClick={() => {
                                setIsTimelineOptionsVisible(false);
                                setSelectedTimeline('10 videos');
                              }}
                            >
                              10 videos
                              <UisCheckCircle
                                size="18"
                                css={css`
                                  margin-left: 1.6rem;
                                `}
                                color="#02C39A"
                              />
                            </button>
                          </li>
                          <li>
                            <button
                              onMouseOver={() => {
                                setHoveredLink('20 videos');
                              }}
                              onMouseOut={() => {
                                setHoveredLink('');
                              }}
                              onClick={() => {
                                setIsTimelineOptionsVisible(false);
                                scrollTo('#cta-section');
                              }}
                            >
                              20 videos{' '}
                              {hoveredLink === '20 videos' ? (
                                <UisUnlock
                                  size="18"
                                  css={css`
                                    margin-left: 1.6rem;
                                  `}
                                  color="#02C39A"
                                />
                              ) : (
                                <UilLock
                                  size="18"
                                  css={css`
                                    margin-left: 1.6rem;
                                  `}
                                  color="#02C39A"
                                />
                              )}
                            </button>
                          </li>
                          <li>
                            <button
                              onMouseOver={() => {
                                setHoveredLink('All videos');
                              }}
                              onMouseOut={() => {
                                setHoveredLink('');
                              }}
                              onClick={() => {
                                setIsTimelineOptionsVisible(false);
                                scrollTo('#cta-section');
                              }}
                            >
                              All{' '}
                              {hoveredLink === 'All videos' ? (
                                <UisUnlock
                                  size="18"
                                  css={css`
                                    margin-left: 1.6rem;
                                  `}
                                  color="#02C39A"
                                />
                              ) : (
                                <UilLock
                                  size="18"
                                  css={css`
                                    margin-left: 1.6rem;
                                  `}
                                  color="#02C39A"
                                />
                              )}
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  css={css`
                    padding: 0 0.2rem;
                    @media (min-width: 700px) {
                      padding: 0 1.2rem;
                    }
                  `}
                >
                  {earningsMultiplier > 1.5 ? (
                    <h5
                      css={css`
                        margin-top: 0.6rem;
                        margin-bottom: 0.8rem;
                        width: 100%;
                      `}
                    >
                      Based on our analysis, we estimate you could be making{' '}
                      <span
                        css={css`
                          color: #fff200;
                        `}
                      >
                        {earningsMultiplier.toFixed(1)}X
                      </span>{' '}
                      more through Direct To Fan Engagement.
                    </h5>
                  ) : (
                    <h5>
                      Based on our analysis we think you should focus on
                      increasing your{' '}
                      <span
                        css={css`
                          color: #fff200;
                        `}
                      >
                        Subscriber Count
                      </span>{' '}
                      before you activate Direct-To-Fan Engagement.
                    </h5>
                  )}
                </div>
                {width > 769 ? (
                  <div
                    css={
                      showChannelInfoCard
                        ? css`
                            left: 0;
                            transition: left 0.8s;
                          `
                        : css`
                            left: -12%;
                          `
                    }
                    className="channel-basic-info-container"
                  >
                    <section>
                      <div className="channel-info-section">
                        <div className="channel-avatar-container">
                          {/* Avatar */}
                          <img
                            src={channelAvatar}
                            alt={`${channelTitle}'s avatar`}
                          />
                        </div>
                        <div
                          css={css`
                            display: grid;
                            grid-template-columns: 1fr;
                          `}
                        >
                          <div className="channel-title-container">
                            <h5 className="channel-title">{channelTitle}</h5>
                          </div>
                        </div>
                      </div>
                      <div className="channel-info-section channel-info-section--2">
                        <div className="channel-info-group">
                          <div
                            css={css`
                              display: flex;
                              flex-flow: row wrap;
                              justify-content: center;
                              width: 100%;
                            `}
                          >
                            <p className="channel-info-title">Subscribers</p>
                          </div>
                          <div>
                            <h5
                              css={css`
                                margin: 0.4rem;
                              `}
                            >
                              {kFormatter(subscriberCount)}
                            </h5>
                          </div>
                        </div>
                        <div className="channel-info-group">
                          <div
                            css={css`
                              display: flex;
                              flex-flow: row wrap;
                              justify-content: center;
                              width: 100%;
                            `}
                          >
                            <p className="channel-info-title">Avg views</p>
                          </div>
                          <div>
                            <h5
                              css={css`
                                margin: 0.4rem 0;
                              `}
                            >
                              {kFormatter(avgViewCount)}
                            </h5>
                          </div>
                        </div>
                      </div>
                      <a
                        target="_blank"
                        css={css`
                          display: flex;
                          flex-flow: row wrap;
                          justify-content: center;
                          align-items: center;
                          border-radius: 0.4rem;
                          background: #1da1f2;
                          width: 96%;
                          text-decoration: none;
                          padding: 0.4rem 0;
                          margin: 0 2% 1rem 2%;
                        `}
                        href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(
                          window.location.href,
                        )}&text=Found out how my channel is performing in this new tool by @CreatorStack. Check it out 👉`}
                      >
                        <p
                          css={css`
                            margin: 0.2rem 0.4rem;
                          `}
                        >
                          Share this!
                        </p>
                        <img
                          src="https://creatorstack.s3.amazonaws.com/Twitter_Logo_WhiteOnBlue.svg"
                          css={css`
                            height: 24px;
                            width: auto;
                          `}
                        />
                      </a>
                    </section>
                  </div>
                ) : (
                  <div
                    css={
                      showChannelInfoCard
                        ? css`
                            bottom: 0;
                            transition: bottom 0.8s;
                          `
                        : css`
                            bottom: -10%;
                          `
                    }
                    className="channel-basic-info-container"
                  >
                    <section>
                      <div className="channel-info-section">
                        <div className="channel-avatar-container">
                          {/* Avatar */}
                          <img
                            src={channelAvatar}
                            alt={`${channelTitle}'s avatar`}
                          />
                        </div>
                        <div
                          css={css`
                            display: grid;
                            grid-template-columns: 1fr;
                          `}
                        >
                          <div className="channel-title-container">
                            <h5 className="channel-title">{channelTitle}</h5>
                          </div>
                        </div>
                      </div>
                      <div className="channel-info-section channel-info-section--2">
                        <div className="channel-info-group">
                          <div
                            css={css`
                              display: flex;
                              flex-flow: row wrap;
                              justify-content: center;
                              width: 100%;
                            `}
                          >
                            <p className="channel-info-title">Subscribers</p>
                          </div>
                          <div>
                            <h5
                              css={css`
                                margin: 0.4rem;
                              `}
                            >
                              {kFormatter(subscriberCount)}
                            </h5>
                          </div>
                        </div>
                        <div className="channel-info-group">
                          <div
                            css={css`
                              display: flex;
                              flex-flow: row wrap;
                              justify-content: center;
                              width: 100%;
                            `}
                          >
                            <p className="channel-info-title">Avg views</p>
                          </div>
                          <div>
                            <h5
                              css={css`
                                margin: 0.4rem 0;
                              `}
                            >
                              {kFormatter(avgViewCount)}
                            </h5>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                )}
                <div
                  css={css`
                    width: 100%;
                    margin-top: 2rem;
                    @media (min-width: 700) {
                      margin: 0;
                    }
                  `}
                  className="channel-performance-summary__plot"
                >
                  {/* Performance summary plot */}
                  <canvas
                    // css={css`
                    //   min-height: 400px;
                    // `}
                    id="performance-chart"
                  ></canvas>
                </div>
                <p
                  css={css`
                    font-size: 0.4rem;
                    font-weight: 200;
                    opacity: 0.6;
                    width: 100%;
                    margin-top: 1rem;
                    text-align: center;
                    @media (min-width: 700px) {
                      font-size: 0.8rem;
                    }
                  `}
                >
                  <em>
                    *This is an estimate based on publicly available YouTube Ad
                    Revenue data and our own proprietary Direct-To-Fan
                    Engagement data.
                  </em>
                </p>
              </section>
              <div
                className="channel-analysis-container"
                css={css`
                  display: flex;
                  flex-flow: row wrap;
                  width: 100%;
                  margin: 2rem 0;
                `}
              >
                <section className="channel-fan-analysis">
                  <div
                    className="channel-fan-analysis__header"
                    css={css`
                      margin: 2rem 0;
                      padding: 0 1.2rem;
                    `}
                  >
                    <h4>Fan analysis</h4>
                    <p
                      css={css`
                        opacity: 1;
                        width: 100%;
                        font-size: 0.8rem;
                        margin-top: 0;
                        margin-bottom: 0.4rem;
                        font-weight: 300;
                        @media (min-width: 700px) {
                          font-size: 0.88rem;
                          width: 72%;
                        }
                      `}
                    >
                      Now let's take a look at your fandom in numbers.
                    </p>
                    <p
                      css={css`
                        opacity: 1;
                        width: 100%;
                        font-size: 0.8rem;
                        margin-top: 0;
                        margin-bottom: 0.4rem;
                        font-weight: 300;
                        @media (min-width: 700px) {
                          font-size: 0.88rem;
                          width: 72%;
                        }
                      `}
                    >
                      You have{' '}
                      <span
                        css={css`
                          font-size: 1.2rem;
                          font-weight: 600;
                          color: #fff200;
                        `}
                      >
                        {trueFanCount.toLocaleString()}
                      </span>{' '}
                      TrueFans and{' '}
                      <span
                        css={css`
                          font-size: 1.2rem;
                          font-weight: 600;
                          color: #fff200;
                        `}
                      >
                        {superFanCount.toLocaleString()}
                      </span>{' '}
                      SuperFans.
                    </p>
                    <p
                      css={css`
                        opacity: 1;
                        width: 100%;
                        font-size: 0.8rem;
                        margin-top: 0;
                        @media (min-width: 700px) {
                          font-size: 0.88rem;
                          width: 72%;
                        }
                      `}
                    >
                      Foster your Fan-Fam with direct engagement with who they
                      love-YOU.{' '}
                    </p>
                  </div>
                  {/* doughnut chart for fan analysis */}
                  <div className="channel-fan-analysis__body">
                    <canvas id="fan-analysis-chart"></canvas>
                  </div>
                </section>
                <section className="channel-earnings-analysis">
                  <div
                    className="channel-earnings-analysis__header"
                    css={css`
                      margin: 2rem 0;
                      padding: 0 1.2rem;
                    `}
                  >
                    <h4>Earnings analysis</h4>
                    <p
                      css={css`
                        opacity: 1;
                        width: 100%;
                        font-size: 0.8rem;
                        margin-top: 0;
                        margin-bottom: 1rem;
                        font-weight: 300;
                        @media (min-width: 700px) {
                          font-size: 0.88rem;
                          width: 72%;
                        }
                      `}
                    >
                      Here's what you could be making with Direct-To-Fan
                      Engagement.
                    </p>
                    <p
                      css={css`
                        opacity: 1;
                        width: 100%;
                        font-size: 0.8rem;
                        margin-top: 0;
                        @media (min-width: 700px) {
                          font-size: 0.88rem;
                          width: 72%;
                        }
                      `}
                    >
                      Engage directly with your community and double down on
                      your earnings.
                    </p>
                  </div>
                  {/* doughnut chart for earnings analysis */}
                  <div className="channel-earnings-analysis__body">
                    <canvas id="earnings-analysis-chart"></canvas>
                  </div>
                </section>
              </div>
              <div className="engagement-metrics-container">
                <section>
                  <div
                    css={css`
                      display: flex;
                      flex-flow: row wrap;
                      width: 100%;
                      padding: 0.8rem 0.4rem;
                    `}
                  >
                    <div
                      css={css`
                        width: 100%;
                        display: flex;
                        flex-flow: row wrap;
                        justify-content: center;
                        align-items: center;
                      `}
                    >
                      <ReactTooltip
                        className="tool-tip"
                        place="top"
                        type="light"
                        effect="float"
                      />
                      <p
                        css={css`
                          margin: 0.2rem 0 0.4rem 0;
                          opacity: 0.6;
                          display: flex;
                          flex-flow: row wrap;
                          align-items: center;
                          font-family: 'Avenir', sans-serif;
                          font-size: 1rem;
                        `}
                      >
                        Avg. engagement per video{' '}
                        <UilQuestionCircle
                          size="16"
                          color="#02C39A"
                          data-place="top"
                          data-tip="Median number of reactions and comments per video in the given period"
                          css={css`
                            margin: 0.1rem 0 0 0.2rem;

                            &:hover {
                              cursor: pointer;
                            }
                          `}
                        />
                      </p>
                    </div>
                    <div
                      css={css`
                        width: 100%;
                        display: flex;
                        flex-flow: row wrap;
                        justify-content: center;
                      `}
                    >
                      <h4
                        css={css`
                          margin: 0.4rem 0 0 0;
                        `}
                      >
                        {avgEngagementPerVideo.toLocaleString()}{' '}
                        <span
                          css={css`
                            font-size: 0.8rem;
                            font-weight: 400;
                            opacity: 0.8;
                            margin-left: -4px;
                          `}
                        >
                          Interactions
                        </span>
                      </h4>
                    </div>
                    <div
                      css={css`
                        width: 100%;
                        display: flex;
                        flex-flow: row wrap;
                        justify-content: center;
                        align-items: center;
                      `}
                    >
                      {avgChangeInEngagementPerVideo > 0 ? (
                        <UilArrowUp size="16" color="#02C39A" />
                      ) : (
                        <UilArrowDown size="16" color="#fc5c65" />
                      )}
                      <p
                        css={css`
                          margin: 0.2rem 0;
                          font-size: 0.8rem;
                          opacity: 0.8;
                        `}
                      >
                        {avgChangeInEngagementPerVideo.toFixed(2)}%
                      </p>
                    </div>
                  </div>
                  <div
                    css={css`
                      width: 100%;
                      display: flex;
                      flex-flow: row wrap;
                      justify-content: space-evenly;
                      margin-top: 0.4rem;
                      padding: 0 0 0.8rem 0;
                    `}
                  >
                    <div>
                      <div
                        css={css`
                          width: 100%;
                          display: flex;
                          flex-flow: row wrap;
                          justify-content: center;
                          align-items: center;
                        `}
                      >
                        <p
                          css={css`
                            font-family: 'Avenir', sans-serif;
                            margin: 0.2rem 0;
                            font-size: 1rem;
                            opacity: 0.6;
                          `}
                        >
                          Reactions
                        </p>
                      </div>
                      <div
                        css={css`
                          width: 100%;
                          display: flex;
                          flex-flow: row wrap;
                          justify-content: center;
                          align-items: center;
                        `}
                      >
                        <p
                          css={css`
                            margin: 0.2rem 0;
                            font-size: 1.4rem;
                          `}
                        >
                          {avgReactionsPerVideo.toLocaleString()}
                        </p>
                      </div>
                      <div
                        css={css`
                          width: 100%;
                          display: flex;
                          flex-flow: row wrap;
                          justify-content: center;
                          align-items: center;
                        `}
                      >
                        {avgChangeInReactionsPerVideo > 0 ? (
                          <UilArrowUp size="16" color="#02C39A" />
                        ) : (
                          <UilArrowDown size="16" color="#fc5c65" />
                        )}
                        <p
                          css={css`
                            margin: 0.2rem;
                            opacity: 0.8;
                            font-size: 0.8rem;
                          `}
                        >
                          {avgChangeInReactionsPerVideo.toFixed(2)}%
                        </p>
                      </div>
                    </div>
                    <div>
                      <div
                        css={css`
                          width: 100%;
                          display: flex;
                          flex-flow: row wrap;
                          justify-content: center;
                          align-items: center;
                        `}
                      >
                        <p
                          css={css`
                            font-family: 'Avenir', sans-serif;
                            margin: 0.2rem 0;
                            font-size: 1rem;
                            opacity: 0.6;
                          `}
                        >
                          Comments
                        </p>
                      </div>
                      <div
                        css={css`
                          width: 100%;
                          display: flex;
                          flex-flow: row wrap;
                          justify-content: center;
                          align-items: center;
                        `}
                      >
                        <p
                          css={css`
                            margin: 0.2rem 0;
                            font-size: 1.4rem;
                          `}
                        >
                          {avgCommentsPerVideo.toLocaleString()}
                        </p>
                      </div>
                      <div
                        css={css`
                          width: 100%;
                          display: flex;
                          flex-flow: row wrap;
                          justify-content: center;
                          align-items: center;
                        `}
                      >
                        {avgChangeInCommentsPerVideo > 0 ? (
                          <UilArrowUp size="16" color="#02C39A" />
                        ) : (
                          <UilArrowDown size="16" color="#fc5c65" />
                        )}
                        <p
                          css={css`
                            margin: 0.2rem;
                            opacity: 0.8;
                            font-size: 0.8rem;
                          `}
                        >
                          {avgChangeInCommentsPerVideo.toFixed(2)}%
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    css={css`
                      display: flex;
                      flex-flow: row wrap;
                      width: 100%;
                      justify-content: center;
                      padding-bottom: 0.4rem;
                    `}
                  >
                    {/* <p
                  css={css`
                    font-weight: 200;
                    opacity: 0.6;
                    font-size: 0.8rem;
                  `}
                >
                  This data provides info for the latest{' '}
                  <strong>10 videos</strong>
                </p> */}
                  </div>
                </section>
              </div>
            </div>
            <div className="cta-section" id="cta-section">
              <div
                className="cta-section__header"
                css={css`
                  padding: 0 1.6rem;
                  display: flex;
                  flex-flow: row wrap;
                  justify-content: center;
                `}
              >
                <h4
                  css={css`
                    text-align: center;
                    margin-bottom: 0.4rem;
                  `}
                >
                  Own your Audience. Boost your Earnings. Free your Creativity{' '}
                  <span role="image">🤩</span>
                </h4>
                <p
                  css={css`
                    text-align: center;
                    margin-bottom: 2.4rem;
                    opacity: 0.8;
                    @media (min-width: 700px) {
                      width: 64%;
                    }
                  `}
                >
                  CreatorStack can help you unlock your True Earning Potential
                  and crush your earning goals. Share your information to learn
                  more.
                </p>
              </div>
              <div
                className="creator-signup-form-container"
                css={css`
                  display: flex;
                  flex-flow: row wrap;
                  width: 100%;
                  justify-content: center;
                `}
              >
                {isContactFormSubmitted ? (
                  <div>
                    <h2
                      css={css`
                        color: #ccc;
                        text-align: center;
                      `}
                    >
                      Thank you for your response! 💌
                    </h2>
                    <p
                      css={css`
                        color: #ccc;
                        text-align: center;
                      `}
                    >
                      Give us a shout out 📣 using the share button if that's
                      cool!
                    </p>
                  </div>
                ) : (
                  <form className="form form--inline" onSubmit={handleSubmit}>
                    {/* flex layout is better here to arrange it vertically on mobile devices */}
                    <div className="input-container">
                      <input
                        className="input"
                        type="email"
                        name="email"
                        placeholder="Email"
                        onChange={handleChange}
                      />
                    </div>
                    <div className="input-seperator">
                      <p>and / or</p>
                    </div>
                    <div className="input-container">
                      <input
                        className="input"
                        type="tel"
                        name="phone"
                        placeholder="Phone number"
                        onChange={handleChange}
                      />
                    </div>
                    <div
                      className="form-action-container"
                      css={css`
                        width: 100%;
                        display: flex;
                        flex-flow: row wrap;
                        justify-content: center;
                        margin: 2.4rem 0;
                      `}
                    >
                      <button
                        type="submit"
                        className="button cta-form-submit-button"
                      >
                        {contactFormSubmissionProcessing ? (
                          <Loader />
                        ) : (
                          'Submit'
                        )}
                      </button>
                      <p
                        css={css`
                          font-size: 0.88rem;
                          opacity: 0.4;
                          width: 100%;
                          text-align: center;
                        `}
                      >
                        By submitting your information you are agreeing to
                        CreatorStack or its representatives to contact you.
                      </p>
                    </div>
                  </form>
                )}
              </div>
            </div>
          </>
        ) : (
          <>
            <Lottie
              options={{
                loop: true,
                autoplay: true,
                animationData: animationData.default,
                rendererSettings: {
                  preserveAspectRatio: 'xMidYMid slice',
                },
              }}
              height={width < 700 ? 320 : 400}
              width={width < 700 ? 320 : 400}
              isStopped={false}
              isPaused={false}
            />
            <div
              className="cta-section"
              css={css`
                display: flex;
                flex-flow: row wrap;
                width: 100%;
                margin: 0;
              `}
            >
              <div
                className="cta-section__header"
                css={css`
                  padding: 0 1.6rem;
                `}
              >
                <h4
                  css={css`
                    text-align: center;
                  `}
                >
                  Or you could give us your deets right away and we can begin
                  unlocking your True Earning Potential through Direct-To-Fan
                  Engagement <span rel="image">🦄</span>
                </h4>
              </div>
              <div
                className="creator-signup-form-container"
                css={css`
                  display: flex;
                  flex-flow: row wrap;
                  width: 100%;
                  justify-content: center;
                `}
              >
                {isContactFormSubmitted ? (
                  <div>
                    <h2
                      css={css`
                        color: #ccc;
                        text-align: center;
                      `}
                    >
                      Thank you for your response! 💌
                    </h2>
                    <p
                      css={css`
                        color: #ccc;
                        text-align: center;
                      `}
                    >
                      Give us a shout out 📣 using the share button if that's
                      cool!
                    </p>
                  </div>
                ) : (
                  <form
                    css={css`
                      display: flex;
                      flex-flow: row wrap;
                      width: 80%;
                      justify-content: space-evenly;
                      padding: 1rem 0;
                    `}
                    className="form form--inline"
                    onSubmit={handleSubmit}
                  >
                    {/* flex layout is better here to arrange it vertically on mobile devices */}
                    <div
                      className="input-container"
                      css={css`
                        width: 45%;
                      `}
                    >
                      <input
                        className="input"
                        type="email"
                        name="email"
                        placeholder="Email"
                        onChange={handleChange}
                      />
                    </div>
                    <div className="input-seperator">
                      <p>and / or</p>
                    </div>
                    <div
                      className="input-container"
                      css={css`
                        width: 45%;
                      `}
                    >
                      <input
                        className="input"
                        type="tel"
                        name="phone"
                        placeholder="Phone number"
                        onChange={handleChange}
                      />
                    </div>
                    {/* <div
                  className="meeting-scheduler-container"
                  css={css`
                    display: flex;
                    flex-flow: row wrap;
                    width: 100%;
                    justify-content: center;
                    align-items: center;
                    margin: 3.2rem 0 1rem 0;
                  `}
                >
                  <p>Alternatively schedule a meetup on </p>
                  <img
                    className="google-meet-logo"
                    src="https://creatorstack.s3.amazonaws.com/meet_96dp.png"
                    alt="Google hangouts icon"
                    css={css`
                      height: 1.6rem;
                      margin-left: 0.4rem;
                    `}
                  />
                </div> */}
                    <div
                      className="form-action-container"
                      css={css`
                        width: 100%;
                        display: flex;
                        flex-flow: row wrap;
                        justify-content: center;
                        margin: 2rem 0;
                      `}
                    >
                      <button
                        className="button cta-form-submit-button"
                        css={css`
                          width: 100%;
                          background: #02c39a;
                          color: #fafafa;
                          font-size: 2rem;
                          font-weight: 600;
                          @media (min-width: 700px) {
                            width: 32%;
                          }
                        `}
                      >
                        {contactFormSubmissionProcessing ? (
                          <Loader />
                        ) : (
                          'Submit'
                        )}
                      </button>
                      <p
                        css={css`
                          font-size: 0.88rem;
                          opacity: 0.4;
                          width: 100%;
                          text-align: center;
                        `}
                      >
                        By submitting your information you are agreeing to
                        CreatorStack or its representatives contacting you.
                      </p>
                    </div>
                  </form>
                )}
              </div>
            </div>
          </>
        )}
        <ToastContainer />
        {/* Uncomment this for auth Implementation with netlify functions */}
        {/* <p>
          <a href="#" onClick={handleIdentity}>
            User Status
          </a>
        </p> */}
      </div>
    </>
  );
};
